import {
  createInvoiceSuccess,
  createInvoiceFailed,
  fetchBuyingInvoicesSuccess,
  fetchBuyingInvoicesFailed,
  fetchDetailInvoiceSuccess,
  fetchBSellingInvoicesSuccess,
  fetchSellingInvoicesFailed,
  updateStatusInvoiceSuccess,
  updateStatusInvoiceFailed,
} from "actions/invoice";

import { put, call, select} from "redux-saga/effects";
import { createInvoice, listInvoiceUser, listInvoiceSeller, sellerViewInvoice, buyerViewInvoice, buyerCancelInvoice, sellerUpdateStatusInvoice } from "apis/invoice";
import { STATUS_CODE } from "constants";

export function* userCreateInvoice({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(createInvoice, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
    }
  } catch (error) {}
}
export function* userGetBuyingInvoicesList({ payload }) {
  try {
    const { params } = payload;
    const respone = yield call(listInvoiceUser, params);
    const { data, status } = respone;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchBuyingInvoicesSuccess(data));
    } else {
      yield put(fetchBuyingInvoicesFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userFetchDetailInvoice({ payload }) {
  try {
    const { params } = payload;
    const { type, invoiceId } = params;
    let response;

    if (type === "seller") {
      // gọi api get detail invoice của người bán
      response = yield call(sellerViewInvoice, invoiceId);
    } else {
      response = yield call(buyerViewInvoice, invoiceId);
      // gọi api get detail invoice của người mua
    }
    const { data, status } = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchDetailInvoiceSuccess({ type: type, detail: data }));
    }
  } catch (error) {}
}
export function* userGetSellingInvoicesList({ payload }) {
  try {
    const { params } = payload;
    const respone = yield call(listInvoiceSeller, params);
    const { data, status } = respone;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchBSellingInvoicesSuccess(data));
    } else {
      yield put(fetchSellingInvoicesFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userUpdateStatusInvoice({ payload }) {
  try {
    const { params } = payload;
    const {type, invoiceUserId} = params;
    let response;
    if(type === 'seller') {
      response = yield call(sellerUpdateStatusInvoice, params);
    }else {
      response = yield call(buyerCancelInvoice, invoiceUserId);
    }
    const { data, status} = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateStatusInvoiceSuccess(data));
    }
  } catch (error) {
    const { params } = payload;
    const {invoiceUserId} = params;
    const response = yield call(sellerViewInvoice, invoiceUserId);
    yield put(updateStatusInvoiceFailed({errorText: error.data, data: response.data}));
  }
}