
const UseInvoice = () => {
  
  const invoiceStatus = {
    pending: {
      className: 'badge-warning',
      text: 'Đang chờ',
    },
    cancelled: {
      className: 'badge-danger',
      text: 'Đã hủy'
    },
    done: {
      className: 'badge-success',
      text: 'Hoàn thành'
    }
  }

  return {
    invoiceStatus,
  }
}

export default UseInvoice;