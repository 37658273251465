import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-sectn">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12 d-flex align-items-center">
              <a className="logo" href="#">
                <img src="/images/logo.png" alt="logo images" title="Go to Homepage" />
              </a>
            </div>
            <div className="col-md-4 col-sm-12 contact-inf">
              <p className="footer-title">Thông tin</p>
              <b className="mt-3">CÔNG TY CỔ PHẦN PHÂN BÓN DẦU KHÍ CÀ MAU</b>
              <div className="mt-2">
                Đại diện: Nguyễn Tấn Đạt
              </div>
              <div>
                GPKD số 2001012298 - Cấp ngày 24/03/2011
              </div>
              <div className="mt-2">
                Địa chỉ: 173 - 179 đường Trương Văn Bang, phường Thanh Mỹ Lợi, Thành phố Thủ Đức, Thành phố Hồ Chí Minh
              </div>
            </div>
            <div className="col-md-3 col-sm-12 contact-inf">
              <p className="footer-title">Liên Hệ</p>
              <div>
                Email:{" "}
                <a href="mailto:contact@hainong.vn" target="_blank">
                  contact@hainong.vn{" "}
                </a>
              </div>
              <div>
                Tell:{" "}
                <a href="tel:19009099" target="_blank">
                  19009099
                </a>
              </div>
              <div>
                Trợ giúp:{" "}
                <a href="http://help.hainong.vn/" target="_blank">
                  help.hainong.vn
                </a>
              </div>
              <div className="social-ico mt-5 d-flex">
                <a href="https://www.facebook.com/2nong.vn?mibextid=LQQJ4d" target="_blank">
                  <span className="icon-meta">
                    <img
                      src="/images/facebook.png"
                      style={{ width: "30px", height: "30px", marginTop: "8px", marginLeft: "1px" }}
                    />
                  </span>
                </a>
                <a href="https://www.youtube.com/channel/UC61g_bNwMvtYyWd4bju7NWA" target="_blank">
                  <span className="icon-youtube"></span>
                </a>
                <a href="https://zalo.me/1966695983625078558" target="_blank">
                  <span className="icon-zalo"></span>
                </a>
                <a href="https://www.tiktok.com/@2nong.tinnongnghiep?_t=8a5CdgRBl1m&_r=1" target="_blank">
                  <span className="icon-tiktok"></span>
                </a>
              </div>
            </div>
            <div className="col-md-2 col-sm-12 contact-inf">
              <p className="footer-title">Chính sách</p>
              <div>Chính sách bảo mật</div>
              <div>Quy chế hoạt động</div>
              <div>Tranh chấp khiếu nại</div>
          </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
