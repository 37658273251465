import React, { useEffect, useState } from 'react';
import {invoiceDetailPublic} from 'apis/invoice';
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import { Link, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import NumberFormat from "components/number_format";
import moment from "moment/moment";
import useInvoice from "../../context/Inovice/useInvoice";
import {imageUrl} from 'lib';

function DetailInvoiceNotAuthen() {
  const { invoiceId } = useParams();
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [error, setError] = useState(false);
  const {invoiceStatus} = useInvoice();

  async function getInvoicePublic(){
    const response = await invoiceDetailPublic(invoiceId);
    return response;
  }

  useEffect(() => {
    getInvoicePublic().then((response) => {
      setInvoiceDetail(response.data.data);
      setError(false);
    }).catch((err) => {
      setError(true)
    });
  }, [invoiceId]);

  return (
    <>
      <AddMetaTags title={`Thông tin đơn hàng - ${invoiceId}`} />
      <div className="detail-invoice">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            {error ? <p className='fw-bold '>Không tìm thấy đơn hàng !</p> : <>  <section>
              <Card>
                <Card.Header>
                  Thông tin đơn hàng
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        <strong>Người nhận: </strong>
                        <span>{invoiceDetail?.name}</span>
                      </p>
                      <p>
                        <strong>Số điện thoại: </strong>
                        <span>{invoiceDetail?.phone_number}</span>
                      </p>
                      <p>
                        <strong>Email: </strong>
                        <span>{invoiceDetail?.email}</span>
                      </p>
                      <p>
                        <strong>Địa chỉ giao hàng: </strong>
                        <span>{invoiceDetail?.shipping_address}</span>
                      </p>
                      <p>
                        <strong>Trạng thái: </strong>
                        <span className={`badge badge-pill ${invoiceStatus[(invoiceDetail?.status)]?.className}`}>&nbsp;{invoiceStatus[(invoiceDetail?.status)]?.text}</span>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                          <strong>Người bán: </strong>
                          <span>{invoiceDetail?.seller_name}</span>
                        </p>
                      <p>
                        <strong>Hình thức giao dịch: </strong>
                        <span>Tiền mặt</span>
                      </p>
                      <p>
                        <strong>Tổng tiền: </strong>
                        <span><NumberFormat>{invoiceDetail?.price_total}</NumberFormat>đ</span>
                      </p>
                      <p>
                        <strong>Thời gian đặt: </strong>
                        <span>{moment(invoiceDetail?.created_at).format("YYYY-MM-DD HH:mm:ss")}</span>
                      </p>
                      <p>
                        <strong>Số lượng mặt hàng: </strong>
                        <span>{invoiceDetail?.quantity}</span>
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </section>
            <section className="mb-3">
              <div className="row">
                <div className="col-md-12">
                <Card className="mt-3">
                  <Card.Header>
                    <p className="fw-bold">Danh sách mặt hàng</p>
                  </Card.Header>
                  <Card.Body>
                  <table className="table align-middle mb-0 bg-white ">
                    <thead className="bg-light">
                      <tr>
                        <th>Tên sản phẩm</th>
                        <th>Giá tiền</th>
                        <th>số lượng</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetail && invoiceDetail?.invoice_items?.map((product) => {
                        return (
                          <tr key={product?.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <Link to={`/san-pham/${product?.product_id}`}>
                                  <img
                                    src={product?.product_images === null || product?.product_images === undefined ? '/images/photos/no_image.png' : imageUrl(product?.product_images[0]?.name)}
                                    className="rounded-circle"
                                    alt=""
                                    style={{ width: "45px", height: "45px" }}
                                  />
                                </Link>
                                <div className="ms-3">
                                <Link to={`/san-pham/${product?.product_id}`}>
                                  <p className="fw-bold mb-1">{product?.product_name}</p>
                                </Link>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="fw-bold mb-1">
                                <NumberFormat>{product?.price}</NumberFormat>₫
                              </p>
                            </td>
                            <td>
                              <div className="cart-quantity">
                                {product?.quantity}
                              </div>
                            </td>
                            <td>
                            </td>
                          </tr>
                        );
                      })}
                       <div className='total-price'>
                          <p className='fw-bold mb-1 mt-2'>
                            Tổng tiền: <NumberFormat>{invoiceDetail?.price_total}</NumberFormat>₫
                          </p>
                        </div>
                    </tbody>
                  </table>
                  </Card.Body>
                </Card>
                </div>
              </div>
            </section>
          </>}
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailInvoiceNotAuthen;
