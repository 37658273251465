import React, {useContext, useEffect, useRef} from "react";
import { Formik, Field, Form} from 'formik';
import AppContext from "components/app_context";
import * as Yup from 'yup';
import {createInvoice} from 'actions/invoice';
import { useDispatch } from "react-redux";
import { useCart } from "../Context";
import _ from "lodash";
import { toastError } from "helpers/toastHelper";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { toastErrorLogin } from '../../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

function UserInformation() {
  const {
    current_user
  } = useContext(AppContext);
  const formikRef = useRef();
  const {listProductInCarts, handleRemoveProduct, handleRemoveAllCart} = useCart();

  useEffect(() => {
    if(current_user) {
      const initialValues = {
        name: current_user?.name || '',
        phone_number: current_user?.phone || '',
        shipping_address: current_user?.address|| '',
        email: current_user?.email || '',
        payment_type: 'Tiền mặt',
      }
      formikRef.current.setValues(initialValues);
    }else{
      formikRef.current.setValues({
        name: '',
        email: '',
        phone_number: '',
        shipping_address: '',
        payment_type: 'Tiền mặt',
      })
    }
  }, [current_user]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validate = Yup.object().shape({
    name: Yup.string().required('Tên không được bỏ trống'),
    phone_number: Yup.string().matches(/^0[1-9]\d{8}$/, 'Số điện thoại không hợp lệ').required('Số điện thoại không được bỏ trống'),
    shipping_address: Yup.string().required('Địa chỉ giao hàng là bắt buộc'),
    email: Yup.string().email('Email không đúng định dạng').matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Email không đúng định dạng')
  });

   async function createOrder(values) {
    const newArrayItem = listProductInCarts.filter(product =>product.user_id === current_user?.id).map(({id, shop_id, quantity}) => ({
      product_id: id,
      shop_id: shop_id,
      quantity: quantity,
    }));

    const newArrayItemFormatted = _.groupBy(newArrayItem, (o) => { return o.shop_id})
    switch (true){
      case !current_user: {
        toastErrorLogin(isMobile)
        // toastError("Bạn chưa đăng nhập")
        break;
      }
      case listProductInCarts.length === 0: {
        toastError("Giỏ hàng trống")
        break;
      }
      default:
        Object.entries(newArrayItemFormatted).forEach(async([key, value]) => {
          const params = ({...values, [`items`]: [...value], [`shop_id`]: key});
          await dispatch(createInvoice(params));
        });

       await Swal.fire(
          'Cám ơn bạn!',
          'Đặt hàng thành công',
          'success'
        ).then((result) => {
          if(result.isConfirmed){
            navigate('/nguoi-dung/don-hang-cua-toi');
          }
        })
        handleRemoveAllCart()
    }
  }
  return (
    <>
      <Formik initialValues={{
          name: '',
          email: '',
          phone_number: '',
          shipping_address: '',
          payment_type: '',
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          createOrder(values);
        }}
        innerRef={formikRef}
        >
        {({errors, touched}) => (
          <Form>
            <div className="user-information mt-3">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <label htmlFor="name">Tên:</label>
                  <div className="name">
                    <Field
                      className="form-control"
                      placeholder="Tên người mua"
                      id="name"
                      name="name"
                    />
                    {errors.name && touched.name && <label className="alert-invalid">{errors.name}</label>}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Địa chỉ:</label>
                  <div className="address-shipping">
                    <Field className="form-control" placeholder="Địa chỉ giao hàng" name="shipping_address"/>
                    {errors.shipping_address && touched.shipping_address && <label className="alert-invalid">{errors.shipping_address}</label>}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Loại thanh toán:</label>
                  <div className="payment-type">
                    <Field className="form-control"  name="payment_type" disabled/>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Email:</label>
                  <div className="email">
                    <Field
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      name="email"
                    />
                    {errors.email && touched.email && <label className="alert-invalid">{errors.email}</label>}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Số điện thoại:</label>
                  <div className="phone">
                    <Field
                      className="form-control"
                      placeholder="Số điện thoại"
                      type="text"
                      name="phone_number"
                    />
                    {errors.phone_number && touched.phone_number && <label className="alert-invalid">{errors.phone_number}</label>}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              <button type="submit" className="btn btn-danger">
                Mua hàng
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UserInformation;
